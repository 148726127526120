import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Hero } from "../hero";

@Component({
  selector: "app-resume",
  templateUrl: "./resume.component.html",
  styleUrls: ["./resume.component.scss"],
})
export class ResumeComponent implements OnInit, AfterViewInit {
  Math: Math;
  hero: Hero = {
    name: "Adham El Banhawy",
    description: `I am an experienced Full Stack Developer with a demonstrated history of working in the higher education industry. I am
    skilled in web development technologies like HTML, CSS, SASS, Javascript Libraries and Frameworks, and CMS like Drupal. My other skills
    include scripting and data mining in python and using various APIs, including google apis. I have done projects using frameworks like
    Reactjs, Angular, and ASP.Net and APIs using python and nodejs.`,
    position: "Software Developer",
    bday: new Date(1992, 7, 20),
    spoken_languages: ["English", "Arabic"],
    stack: {
      HTML: 100,
      CSS: 100,
      Javascript: 98,
      Python: 95,
      SQL: 80,
      Go: 70,
      Java: 60,
      AWS: 100,
      Docker: 100,
      GraphQL: 100,
      NoSQL: 100,
      REST: 100
    },
    work_exp: [
        {
        position: "Software Developer",
        start: "2023",
        end: "present",
        employer: "Alocai GmbH",
        roles: [
          "Introduce new devops best practices to speed up release cycles",
          "Architect and implement new cloud architecture that improved resliniancy & scalability, and reduced operating costs",
          "Maintain & modernize legacy code",
          "Used AWS resources to build, test, deploy and automate new webapps.",
          "Improve and upgrade existing release pipelines to enhancing development speed"
        ],
      },
      {
        position: "Application Developer",
        start: new Date(2021, 0),
        end: new Date(2023, 0),
        employer: "IBM",
        roles: [
          "Translating IT requirements in the design, development",
          "Assembly of components to create custom information systems.",
          "Used AWS resources to build, test, deploy and automate new webapps.",
          "API enhancement & testing.",
          "Migrated complex systems and workloads to AWS cloud.",
        ],
      },
      {
        position: "Senior JS Developer",
        start: new Date(2018, 0),
        end: new Date(2019, 8),
        employer: "Best Response Media UK",
        roles: [
          "Customized and built new templates with React, Redux, and GraphQL",
          "Implemented design-patterns best practices to maximize site load speed and delivery",
          "Used AWS resources to build, test, deploy and automate new webapps",
          "Customized, maintained, and deployed Magento backends",
        ],
      },
      {
        position: "Full Stack Developer",
        start: new Date(2018, 6),
        end: new Date(2018, 9),
        employer: "Radivision",
        roles: [
          "Assisted witt authentication flows and improved projected costs using AWS Cognito services",
          "Refactored existing code base and increased modularity using AWS Lambdas",
          "Improved GraphQL schemas related to logins and authentication",
          "Used Reactjs, Typescript, GraphQL, and AWS extensively",
        ],
      },
      {
        position: "Full Stack Developer",
        start: new Date(2018, 3),
        end: new Date(2018, 6),
        employer: "Barkotel Communications",
        roles: [
          "Lead engineer in a startup branch",
          "Maintained and added new features to a cross-platform Angular application",
          "Refactored existing code base and reduced redundancy by 60%",
          "Implemented CI/CD via Azure and sped up release process by 40%",
          "Attended networking events and pitch product to investors",
        ],
      },
      {
        position: "Web Developer and Digital Marketer Assistant",
        start: new Date(2017, 2),
        end: new Date(2018, 2),
        employer:
          "University of Minnesota, Twin Cities - Office of Governemnt Relations",
        roles: [
          "Working with other developers to code, develop, and update a variety of University websites",
          "Testing websites for browser compatibility, accessibility and overall reliability",
          "Assisting with digital marketing, including SEO and content management strategy",
          "Writing scripts to automate excel sheets automation, web analytics, and web scraping",
        ],
      },
      {
        position: "Project Coordinator",
        start: new Date(2016, 3),
        end: new Date(2016, 10),
        employer: "DaVincian Healthcare",
        roles: [
          "Worked with different stakeholders to communicate requirements, collaborate, and delegate tasks",
          "Improved existing documentation and reduced redundancy by 50%",
          "Worked closely with CTO to translate business requirements to technical requirements and estimate time costs",
          `Assisted marketing VP with market research using different national databases and produced reports to determine different market
          shares of competitive products`,
        ],
      },
      {
        position: "Business Analyst Intern",
        start: new Date(2015, 9),
        end: new Date(2016, 2),
        employer: "American Technology Consulting",
        roles: [
          "Received training in business analysis and project management concepts",
          "Practiced gathering and documenting requirements",
          `Received training in agile and scrum methodologies`,
        ],
      },
    ],
    industry_skills: {
      // the second element is a random number for circle animation duration
      Agile: [100, this.getRandNum()],
      "Front-End": [95, this.getRandNum()],
      "Bck-End": [90, this.getRandNum()],
      SASS: [100, this.getRandNum()],
      ReactJs: [90, this.getRandNum()],
      Angular: [90, this.getRandNum()],
      DevOps: [80, this.getRandNum()],
      AWS: [90, this.getRandNum()],
    },
    credly_ids: [
      { id: "20e7321d-191b-4516-9e97-8d7d8d48afd8" },
      { id: "c102fcd1-066c-4277-a683-80a316794bd3" },
      { id: "f0f32884-ffb3-4f03-bad9-6de3fa34e2ce" },
      { id: "0814049d-c986-4686-8520-a5c117bce100" },
      { id: "19001f6b-ae2c-4a6c-bf79-3cf71e0c49c4" },
      { id: "a82f24b3-214a-4fd8-b0cc-90b1ee6a3cb9" },
    ],
  };

  constructor(private renderer2: Renderer2, private el: ElementRef) {
    this.Math = Math;
  }

  ngOnInit() {
    // this.credly_ids = [
    //   { id: "0814049d-c986-4686-8520-a5c117bce100" },
    //   { id: "19001f6b-ae2c-4a6c-bf79-3cf71e0c49c4" },
    //   { id: "a82f24b3-214a-4fd8-b0cc-90b1ee6a3cb9" },
    //   { id: "f0f32884-ffb3-4f03-bad9-6de3fa34e2ce" },
    //   { id: "c102fcd1-066c-4277-a683-80a316794bd3" },
    // ];
  }

  ngAfterViewInit() {
    let scriptEl = document.createElement("script");
    scriptEl.src = "https://cdn.credly.com/assets/utilities/embed.js";
    this.renderer2.appendChild(this.el.nativeElement, scriptEl);
  }

  getRandNum() {
    return Math.floor(Math.random() * 1000);
  }
}
