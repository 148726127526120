import { Component, OnInit } from '@angular/core';
import { Hero } from '../hero';
import Typed, { TypedOptions } from 'typed.js';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {
  hero: Hero = {
    name: 'Adham El Banhawy',
    description: `I am a software engineer with a passion for web development. I made an early career switch (8 months into my first
      non-techional job) and decided to pursue a degree in computer science to become a good software engineer. I worked part-time as a web
      developer at the University of Minnesota, Twin-Cities, done contract jobs (mostly back-end) at several startups and occasionally do
      freelance work.`,
    extra_description: `When I am not busy working studying or working I like to learn about JS frameworks like Angular and React and do
    some fun personal projects and solve puzzles.`
  };
  constructor() {}
  ngOnInit() {}

  handleSVG(svg: SVGElement): SVGElement {
    ((svgVariable: SVGElement) => {
      setTimeout(() => {
        const options: TypedOptions = {
          strings: [
            'a Web Developer.',
            'a Blogger.',
            'a Gamer.',
            'a FullStack Developer.',
            'a Tea Lover.',
            'an Automation Geek.',
            'a JavaScript Wizard.',
            'a Software Engineer.'
          ],
          typeSpeed: 90,
          smartBackspace: true,
          backSpeed: 20,
          backDelay: 2000,
          cursorChar: '|',
          loop: true
        };

        const typed = new Typed('.monitor', options);
        const typedCursor = svgVariable.querySelector('.typed-cursor');
        typedCursor.setAttribute('style', 'font-size: 50px; color: green');
        const screen = svgVariable.querySelector('#Capa_1');
        screen.setAttribute('style', `max-width: 97vw;`);
      }, 300);
    })(svg);
    return svg;
  }
}
