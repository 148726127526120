import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'counter'
})
export class CounterPipe implements PipeTransform {
  transform(value: any): number[] {
    const arr = [];
    for (let i = 0; i < value; i++) {
      arr.push(i + 1);
    }
    return arr;
  }
}
