import { Component, OnInit } from "@angular/core";
import { IsotopeOptions } from "ngx-isotope";
interface Project {
  title: string;
  description: string;
  category: ProjectCategory[];
  image?: string;
  link?: string;
}
enum ProjectCategory {
  javascript = "Javascript",
  python = "Python",
  node = "Nodejs",
  react = "React",
  angular = "Angular",
  vue = "Vue",
  redux = "Redux",
}
@Component({
  selector: "app-portfolio",
  templateUrl: "./portfolio.component.html",
  styleUrls: ["./portfolio.component.scss"],
})
export class PortfolioComponent implements OnInit {
  public gridOptions: IsotopeOptions = { itemSelector: ".grid-item" };
  constructor() {}
  filteredProjects: Project[];
  projects: Project[];
  oneProject = false;
  categories = {
    All: true,
    JavaScript: false,
    Python: false,
    React: false,
    Vue: false,
    Redux: false,
  };
  ngOnInit() {
    this.projects = [
      {
        title: "Siga Webgame",
        description: `A web-based offilne board game made with Vuejs`,
        link: "https://banhawy.github.io/army-seega/",
        category: [ProjectCategory.vue, ProjectCategory.javascript],
        image: "assets/projects/siga.png",
      },
      {
        title: "Radivision",
        description: `A web applcation that caters to startups and entrepreneurs and is very
         heavily data-driven. I contributed to improving the methods and components used in the React front-end, providing and connecting
         to APIs. On the backend, helped build microservices that handled authentications and logins.`,
        link: "https://www.radivision.com/",
        category: [ProjectCategory.react, ProjectCategory.javascript],
        image: "assets/projects/radivision.png",
      },
      {
        title: "TimeStamp Microservice",
        description: `A simple web API that take string and unix dates and returns their proper formatting in DateString and Unix in JSON
      format. This project is built with Node.js and Express.`,
        link: "https://adham-timestamp-ms.herokuapp.com/",
        category: [ProjectCategory.node, ProjectCategory.javascript],
        image: "assets/projects/timestamp.png",
      },
      {
        title: "Wikipedia Viewer",
        description: `A SPA that takes user input and queries Wikipedia for a given term. Results are formatted and listed after
        asynchronous call is made. This project is built with React, JSX, and Material-UI.`,
        link: "https://banhawy.github.io/Wikipedia-Viewer/",
        category: [ProjectCategory.javascript, ProjectCategory.react],
        image: "assets/projects/wiki.png",
      },
      {
        title: "Bulletin Board",
        description: `This is a virtual bulletin board one-page application built with React. It's my first project learning React. Used
        React components, ES6 syntax, and React production environment.`,
        link: "https://banhawy.github.io/Bulletin-Board/",
        category: [ProjectCategory.javascript, ProjectCategory.react],
        image: "assets/projects/bulletin.png",
      },
      {
        title: "Classic Tomatoes",
        description: `This is a single page web app built entirely with python code. Used movie APIs and some jQuery and bootstrap. It is
        easily customizable and can genrate a movie library with trailers and info by just inserting movie title names in a python list.`,
        link: "https://banhawy.github.io/Classic_Tomatoes/",
        category: [ProjectCategory.python],
        image: "assets/projects/classicT.png",
      },
      {
        title: "Random Quote Generator",
        description: `This is a a web app that generates random quotes from different world leader. I wrote the JSON API that serves the
        app myself and fetched it via ajax. The app changes the background and displays a portrait of the leader with a link to his/her
        wiki page and a tweet button.`,
        link: "https://codepen.io/Banhawy/full/rWZxMv/",
        category: [ProjectCategory.javascript],
        image: "assets/projects/rqg.png",
      },
      {
        title: "Markdown Previewer",
        description: `A Single Page Application that dynamically changes Markdown format to HTML.
        Built with React + Redux.`,
        link: "https://codepen.io/Banhawy/full/QzeZNR",
        category: [
          ProjectCategory.javascript,
          ProjectCategory.react,
          ProjectCategory.redux,
        ],
        image: "assets/projects/markdown.png",
      },
    ];
    this.filteredProjects = this.projects;
  }
  /**
   * Register the project category name clicked
   * @param val The category name clicked
   */
  clickedCategory(val: string) {
    const category = val.toLocaleLowerCase();
    // Toggle selected class on categories buttons
    for (const key in this.categories) {
      if (this.categories.hasOwnProperty(key)) {
        this.categories[key]
          ? (this.categories[key] = false)
          : (this.categories[key] = false);
        if (key === val) {
          this.categories[key] = true;
        }
      }
    }
    if (category === "all") {
      this.filteredProjects = this.projects;
    } else {
      this.filteredProjects = this.filterProjects(category);

      // This bit sets a boolean when filtered projects is <2 for dynamic styling
      // of the description paragraph padding
      if (this.filteredProjects.length < 2) {
        this.oneProject = true;
      } else {
        this.oneProject = false;
      }
    }
  }
  /**
   * Filters the projects list by a given category
   * @param val The category name to filter
   */
  filterProjects(val: string): Project[] {
    return this.projects.filter((project) => {
      return project.category.indexOf(ProjectCategory[val]) !== -1;
    });
  }
  /**
   * Set the background-image style property of the project gallery card
   * @param url the project image path
   */
  setBackground(url: string): string {
    return `url(${url})`;
  }
  onHover(project: Project) {}
}
