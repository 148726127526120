import { Component, OnInit } from "@angular/core";
import { IsotopeOptions } from "ngx-isotope";

interface Blog {
  title: string;
  topics: Topic[];
  publishers: Publisher[];
  image?: string;
  link?: string;
}

interface Publisher {
  name: string;
  link: string;
  icon: PublisherIcon;
}

enum PublisherIcon {
  fcc = 'fab fa-free-code-camp',
  medium = 'fab fa-medium',
  dev = 'fab fa-dev'
}
enum Topic {
  javascript = "Javascript",
  go = "Go",
  python = "Python",
  nodejs = "Nodejs",
  react = "React",
  lifelessons = "LifeLessons",
  cloud = "Cloud",
  devops = "DevOps",
  general = "General"
}

const fcc: Publisher = {
  name: 'freecodecamp',
  link: 'https://www.freecodecamp.org/news/author/adham-el-banhawy/',
  icon: PublisherIcon.fcc
}

const medium: Publisher = {
  name: 'medium',
  link: 'https://medium.com/@banhawy',
  icon: PublisherIcon.medium
}

const devto: Publisher = {
  name: 'dev.to',
  link: 'https://dev.to/adham_benhawy',
  icon: PublisherIcon.dev
}

@Component({
  selector: "app-portfolio",
  templateUrl: "./blog.component.html",
  styleUrls: ["./blog.component.scss"],
})
export class BlogComponent implements OnInit {
  public gridOptions: IsotopeOptions = { itemSelector: ".grid-item" };
  constructor() { }
  filteredBlogs: Blog[];
  blogs: Blog[];
  oneProject = false;
  topics: any = {
    All: true
  };
  topicValues: any;
  ngOnInit() {
    this.blogs = [
      {
        title: `3 Ways to Configure Terraform to use your AWS Account`,
        link: 'https://banhawy.medium.com/3-ways-to-configure-terraform-to-use-your-aws-account-fb00a08ded5',
        topics: [Topic.cloud, Topic.devops],
        image: 'https://miro.medium.com/max/720/1*uROgj7pJBK95JibA-PKDHA.webp',
        publishers: [medium]
      },
      {
        title: `How to Preserve SPA route path in the browser using AWS CloudFront`,
        link: 'https://dev.to/aws-builders/how-to-preserve-spa-route-path-in-the-browser-using-aws-cloudfront-oai',
        topics: [Topic.cloud, Topic.react],
        image: 'https://res.cloudinary.com/practicaldev/image/fetch/s--As3ZnHqc--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://cdn-images-1.medium.com/max/800/0%2ATFuVkgU1YtUzJwLy',
        publishers: [devto, medium]
      },
      {
        title: `My New Year’s Programming Resolution #2022`,
        link: 'https://dev.to/adham_benhawy/my-new-years-programming-resolution-2022-3n97',
        topics: [Topic.general],
        image: 'https://images.unsplash.com/photo-1552529220-460eec1fd555?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
        publishers: [devto, medium]
      },
      {
        title: `How I built a CI/CD Pipeline for My Website using AWS`,
        link: 'https://banhawy.medium.com/how-i-built-a-ci-cd-pipeline-for-my-website-using-aws-296cd95a795f',
        topics: [Topic.cloud, Topic.devops],
        image: 'https://miro.medium.com/max/1400/0*6NQP0xMiY6I_LYlY',
        publishers: [medium]
      },
      {
        title: "How to Backup Squarespace-Managed Styles using AWS Cloud",
        link: "https://www.freecodecamp.org/news/how-to-backup-squarespace-managed-styles-using-aws-cloud/",
        topics: [Topic.cloud],
        image: "https://www.freecodecamp.org/news/content/images/size/w300/2021/06/backup-with-aws-cloud.jpg",
        publishers: [fcc, devto]
      },
      {
        title: `How to Receive Emails from Your Site's "Contact Us" form Using AWS SES, Lambda, & API Gateway`,
        link: 'https://www.freecodecamp.org/news/how-to-receive-emails-via-your-sites-contact-us-form-with-aws-ses-lambda-api-gateway/',
        topics: [Topic.cloud],
        image: 'https://cdn-media-2.freecodecamp.org/w1280/605b0cfb687d62084bf6bd50.jpg',
        publishers: [fcc]
      },
      {
        title: `My New Year’s Programming Resolution #2021`,
        link: 'https://banhawy.medium.com/my-new-years-programming-resolution-2021-63686703ba6',
        topics: [Topic.general],
        image: 'https://miro.medium.com/max/7776/0*qIuihDbOkToUV6hs',
        publishers: [medium]
      },
      {
        title: 'How I Kept Coding While Serving in the Military',
        link: 'https://www.freecodecamp.org/news/how-i-kept-coding-while-serving-in-the-military/',
        topics: [Topic.lifelessons],
        image: 'https://cdn-media-2.freecodecamp.org/w1280/606e0ff0d5756f080ba961a8.jpg',
        publishers: [fcc]
      },
      {
        title: `How I deal with unfair scrutiny: The problem of standing out`,
        link: 'https://banhawy.medium.com/how-i-deal-with-unfair-scrutiny-the-problem-of-standing-out-b8c6f6b3f7bf',
        topics: [Topic.general],
        image: 'https://miro.medium.com/max/2000/0*U62-GuS9y6v3gDN4',
        publishers: [medium]
      },
      {
        title: `My New Year’s Programming Resolution [2019]`,
        link: 'https://banhawy.medium.com/my-new-years-programming-resolution-11ec3b255136',
        topics: [Topic.general],
        image: 'https://miro.medium.com/max/4000/0*pKtV0i9L7oGhD-U7',
        publishers: [medium]
      },
      {
        title: "How to make a Promise out of a Callback function in JavaScript",
        link: "https://medium.com/free-code-camp/how-to-make-a-promise-out-of-a-callback-function-in-javascript-d8ec35d1f981",
        topics: [Topic.javascript, Topic.nodejs],
        image: "https://miro.medium.com/max/1000/1*7EYmjkeLQs8QXSLr_6iTMg.jpeg",
        publishers: [medium]
      },
      {
        title: `How to treat your Muslim co-worker/employee in Ramadan`,
        link: 'https://banhawy.medium.com/how-to-treat-your-muslim-co-worker-employee-in-ramadan-3b60ff59e57e',
        topics: [Topic.general],
        image: 'https://miro.medium.com/max/12000/0*s87n4zha9SHVkQ9G.',
        publishers: [medium]
      },
      {
        title: `How I dropped out of an American CS program, went home to Egypt, and immediately got a dev job at a startup`,
        link: 'https://medium.com/free-code-camp/how-i-dropped-out-of-an-american-cs-program-went-home-to-egypt-and-immediately-got-a-dev-job-at-a-de8b0a854f58',
        topics: [Topic.lifelessons],
        image: 'https://miro.medium.com/max/1400/1*9l77GzTGGWwOjgKs5llUxQ.jpeg',
        publishers: [medium]
      },
      {
        title: `The MERN Challenge: Building the FCC Twitch App using MongoDB, Express, Reactjs, and Nodejs`,
        link: 'https://banhawy.medium.com/the-mern-challenge-building-the-fcc-twitch-app-using-mongodb-express-reactjs-and-nodejs-ca725b351008',
        topics: [Topic.javascript, Topic.nodejs],
        image: 'https://miro.medium.com/max/2866/1*tyiR_Et7FEA9VkXJBrGSDw.png',
        publishers: [medium]
      },
       {
        title: `Goroutines in Go vs. Promises in Node.js: A Comparison of Concurrency Models`,
        link: 'https://medium.com/@banhawy/goroutines-in-go-vs-promises-in-node-js-a-comparison-of-concurrency-models-a6c0c816fc4f',
        topics: [Topic.javascript, Topic.nodejs, Topic.go],
        image: 'https://miro.medium.com/v2/resize:fit:2000/format:webp/0*n7GfEizmr4bRdURH',
        publishers: [medium]
      },
      {
        title: `How to switch to HTTPS for Sites Hosted on AWS using CloudFront & Route53`,
        link: 'https://banhawy.medium.com/switching-to-https-for-sites-hosted-on-aws-341a825b0ae5',
        topics: [Topic.cloud, Topic.devops],
        image: 'https://miro.medium.com/max/1200/0*k9hTjcIQw_qUn_Da.jpg',
        publishers: [medium]
      },
      {
        title: `Parsing Command Line Arguments in Python`,
        link: 'https://banhawy.medium.com/parsing-command-line-arguments-in-python-134a39a0629f',
        topics: [Topic.python, Topic.devops],
        image: 'https://cdn.searchenginejournal.com/wp-content/uploads/2021/09/command-line-interface-61364f6cbfacf-sej-1520x800.jpg',
        publishers: [medium]
      },
      {
        title: `How to use google spreadsheets to check for broken links`,
        link: 'https://banhawy.medium.com/how-to-use-google-spreadsheets-to-check-for-broken-links-1bb0b35c8525',
        topics: [Topic.javascript],
        image: 'https://miro.medium.com/max/1400/1*eVoxIgsHFBMnzUA7it7t1A.png',
        publishers: [medium]
      },
      {
        title: `How I made a Python Web Crawler to automate a boring daily task`,
        link: 'https://banhawy.medium.com/how-i-made-a-python-web-crawler-to-automate-a-boring-daily-task-afb22fbffcc8',
        topics: [Topic.python],
        image: 'https://media.wired.com/photos/5e59a85635982c0009f6eb8a/master/pass/python-popularity.jpg',
        publishers: [medium]
      },
      {
        title: "How freeCodeCamp helped me get a job and turn my life around",
        link: "https://www.freecodecamp.org/news/how-freecodecamp-helped-me-get-a-job-and-turn-my-life-around-5ffe807085cf/",
        topics: [Topic.lifelessons],
        image: "assets/blogs/beat-depression-coding.png",
        publishers: [fcc, medium]
      },
      {
        title: `3 Ways to Create React Components`,
        link: 'https://banhawy.medium.com/3-ways-to-create-react-components-8b3620e4ea0',
        topics: [Topic.react],
        image: 'https://frontendmasters.com/static-assets/learn/og-learning-path-react.jpg',
        publishers: [medium]
      },
      {
        title: `How Coding Helps Treat My Depression`,
        link: 'https://banhawy.medium.com/how-coding-helps-treat-my-depression-f8d016f7e98e',
        topics: [Topic.lifelessons],
        image: 'https://miro.medium.com/max/1400/1*-BjRGQTwztgqY4AxjxA-CA.jpeg',
        publishers: [medium]
      },
    ];
    this.filteredBlogs = this.blogs;
    this.topicValues = [...new Set(this.blogs.reduceRight((acc, curr) => acc.concat(curr.topics), this.blogs[0].topics))].values()
    for (const topic of this.topicValues) {
      this.topics[topic] = false
    }
  }
  /**
   * Register the project category name clicked
   * @param val The category name clicked
   */
  clickedCategory(val: string) {
    const topic = val.toLocaleLowerCase();
    // Toggle selected class on categories buttons
    for (const key in this.topics) {
      if (this.topics.hasOwnProperty(key)) {
        this.topics[key]
          ? (this.topics[key] = false)
          : (this.topics[key] = false);
        if (key === val) {
          this.topics[key] = true;
        }
      }
    }
    if (topic === "all") {
      this.filteredBlogs = this.blogs;
    } else {
      this.filteredBlogs = this.filterBlogs(topic);
      // This bit sets a boolean when filtered projects is <2 for dynamic styling
      // of the description paragraph padding
      if (this.filteredBlogs.length < 2) {
        this.oneProject = true;
      } else {
        this.oneProject = false;
      }
    }
  }
  /**
   * Filters the projects list by a given category
   * @param val The category name to filter
   */
  filterBlogs(val: string): Blog[] {
    return this.blogs.filter((blog) => {
      return blog.topics.indexOf(Topic[val]) !== -1;
    });
  }
  /**
   * Set the background-image style property of the project gallery card
   * @param url the project image path
   */
  setBackground(url: string): string {
    return `url(${url})`;
  }
}
