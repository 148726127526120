import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, RouterOutlet } from '@angular/router';
import { slideInAnimation } from './animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
    // animation triggers go here
  ]
})
export class AppComponent implements OnInit {
  title = `Adham's Website`;
  links: string[] = ['home', 'about', 'resume', 'blog'];
  selectedComponent: string;

  constructor(
    private router: Router,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.selectedComponent = event.url.slice(1);
      }
    });
  }

  ngOnInit() {
    this.selectedComponent = 'about';
  }
  onSelect(link: string) {
    this.selectedComponent = link;
  }

  /**
   * a method that can detect when a view changes. The method assigns an animation state value to the animation trigger (@routeAnimation)
   * based on the route configuration data property value.
   */
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
