import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Renderer2,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import gsap from "gsap/all";
import Draggable from "gsap/Draggable";
import { CounterPipe } from "./../counter.pipe";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"],
})
export class HomePageComponent implements OnInit, AfterViewInit, OnDestroy {
  private static lampLights: Element[] = [];
  @ViewChild("monthname") cal: ElementRef;
  clockInterval: NodeJS.Timer;
  daylightStyles: {};
  lightsTurnedOn: boolean;
  constructor() { }
  ngAfterViewInit() { }
  ngOnInit() {
    this.setDaylighttyles();
    gsap.registerPlugin(Draggable);
  }
  getCurrentBackgroundColor(): string {
    const day = "";
    const lightDark = "rgba(44, 44, 37, 0.7)";
    const darkDark = "rgb(8 8 5 / 85%)";
    const currentHours = new Date().getHours();
    const currentMins = new Date().getMinutes();
    const dusk = currentHours >= 18 && currentMins > 30 && currentHours < 19;
    const dawn = currentHours >= 4 && currentMins > 30 && currentHours <= 6;
    const night = currentHours >= 19;
    const pastMidnight = currentHours >= 0 && currentHours < 4;
    if (dusk || dawn) return lightDark;
    if (night || pastMidnight) return darkDark;
    return day;
  }

  handleSVG(svg: SVGElement): SVGElement {
    const monthname = Array.from(svg.getElementsByClassName("monthname"));
    const clock = Array.from(svg.getElementsByClassName("clock"));
    const Switch = svg.querySelector("#switch");
    const lamp = svg.querySelector("#lamp");
    const window = svg.querySelector(".drag-left");

    const getMonth = () => {
      const date = new Date();
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[date.getMonth()].toUpperCase();
      return month;
    };
    const getDay = () => {
      const date = new Date();
      const numDay = date.getDate();
      return numDay;
    };
    if (monthname.length > 0) {
      monthname["0"].innerHTML = getMonth();
      const daynum = svg.getElementsByClassName("daynum");
      daynum["0"].innerHTML = getDay();
    }
    if (clock.length > 0) {
      this.clockInterval = setInterval(() => {
        const rotate = (id: string, deg: number) => {
          const time = svg.querySelector(`#${id}`);
          time.setAttribute("transform", `rotate(${deg} 43.5 43.5)`);
        };
        const date = new Date();
        rotate("sec", 6 * date.getSeconds());
        rotate("min", 6 * date.getMinutes());
        rotate("hour", 30 * (date.getHours() % 12) + date.getMinutes() / 2);
      }, 1000);
    }
    const isNight = () => {
      const lightDark = "rgba(44, 44, 37, 0.7)";
      const darkDark = "rgb(8 8 5 / 85%)";
      const currentHours = new Date().getHours();
      const currentMins = new Date().getMinutes();
      const dusk = currentHours >= 18 && currentMins > 30 && currentHours < 19;
      const dawn = currentHours >= 4 && currentMins > 30 && currentHours <= 6;
      const night = currentHours >= 19;
      const pastMidnight = currentHours >= 0 && currentHours < 4;
      return dusk || dawn || night || pastMidnight;
    };
    const turnOnTheLights = () => {
      HomePageComponent.lampLights.forEach((lampElement) => {
        lampElement.setAttribute("style", "opacity: 1");
      });
      this.lightsTurnedOn = true;
    };
    const turnOffTheLights = () => {
      HomePageComponent.lampLights.forEach((lampElement) => {
        lampElement.setAttribute("style", "opacity: 0");
      });
      this.lightsTurnedOn = false;
    };
    if (lamp !== null && lamp !== undefined) {
      HomePageComponent.lampLights.push(svg.querySelector("polygon.light"));
      const currentHours = new Date().getHours();
      const currentMins = new Date().getMinutes();
      const dusk = currentHours >= 18 && currentMins > 30 && currentHours < 19;
      const dawn = currentHours >= 4 && currentMins > 30 && currentHours <= 6;
      const night = currentHours >= 19;
      const pastMidnight = currentHours >= 0 && currentHours < 4;
      if (dusk || dawn || night || pastMidnight) turnOnTheLights();
    }
    if (Switch !== null && Switch !== undefined) {
      const switcherOn = svg.querySelector(".switch--on");
      const switcherOff = svg.querySelector(".switch--off");
      if (isNight()) {
        console.log("wtf");
        switcherOn.setAttribute("style", "display: none");
        switcherOff.setAttribute("style", "display: block");
      } else {
        switcherOn.setAttribute("style", "display: block");
        switcherOff.setAttribute("style", "display: none");
      }
      switcherOn.addEventListener("click", () => {
        turnOnTheLights();
        switcherOn.setAttribute("style", "display: none");
        switcherOff.setAttribute("style", "display: block");
      });
      switcherOff.addEventListener("click", () => {
        // HomePageComponent.lamps.reverse(-3);
        // HomePageComponent.lampLights.setAttribute('style', 'opacity: 0');
        turnOffTheLights();
        switcherOn.setAttribute("style", "display: block");
        switcherOff.setAttribute("style", "display: none");
      });
    }
    if (window !== null && window !== undefined) {
      const winLeft = svg.querySelector(".drag-left");
      const winRight = svg.querySelector(".drag-right");
      Draggable.create(winLeft, {
        type: "y",
        throwProps: true,
        lockAxis: true,
        cursor: "ns-resize",
        bounds: {
          minY: 0,
          maxY: 128,
        },
      });
    }

    return svg;
  }
  setDaylighttyles() {
    // CSS styles: set per current state of component properties
    this.daylightStyles = {
      "background-color": this.getCurrentBackgroundColor(),
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    };
  }
  ngOnDestroy() {
    clearInterval(this.clockInterval);
  }
}
